/* eslint-disable complexity, consistent-return */
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { get, some } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { parse } from 'query-string';

import {
  getSelectedOrder,
  fetchOrderDetailsXHR,
  getIsOrderValidating,
  getWarningsForReorder,
  getErrorsForOrder,
} from 'modules/order';
import {
  getRestaurantDetails,
  getRestaurantMembershipEnabled,
} from 'modules/restaurant';

import { USER_ACCOUNT_ID_MISMATCH, NOT_FOUND } from 'helpers/api';
import { redirectToMarketplaceMenu } from 'helpers/configureRedirects';
import {
  CONFIRMATION_NEW_USER_COOKIE,
  CONFIRMATION_ROKT_ADS_COOKIE,
} from 'helpers/constants';
import { getCnChannel } from 'helpers/customer';
import { isSameDay, isWithin24Hours } from 'helpers/date';
import { formatSimplePhone } from 'helpers/format';
import { logAnalyticsEvent, ANALYTICS_EVENT_NAME } from 'helpers/loggers';
import { MODAL_TYPE } from 'helpers/modals';
import {
  getIsOrderFulfilled,
  ORDER_STATUSES,
  FULFILLMENT_METHODS,
  getTriggerPostPurchaseModal,
  removeTriggerPostPurchaseModal,
} from 'helpers/order';
import { isDirectUrl } from 'helpers/url';
import { showNotification, NOTIFICATION_PERMISSIONS } from 'helpers/util';
import { isDineInUrl } from '@chownow/cn-web-utils/url';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';
import { useRoktLauncher } from 'context/RoktAdsContext';

import icon from 'images/logo.png';
import Navbar from 'components/Navbar';
import OrderSummary from 'components/OrderSummary';
import GoogleMap from 'components/GoogleMap';
import BranchIOBanner from 'components/BranchIOBanner';
import POAToast from 'components/POAToast';
import Disclaimer from 'components/Disclaimer';
import Support from 'containers/Support';
import LoadingSpinner from 'primitives/LoadingSpinner';
import MapGrid from 'primitives/MapGrid';
import TagNotice from 'primitives/TagNotice';

import usePlatform from 'hooks/usePlatform';
import usePrevious from 'hooks/usePrevious';
import useIsMobile from 'hooks/useIsMobile';
import useReorder from 'hooks/useReorder';
import useFetchNativeApp from 'hooks/useFetchNativeApp';
import useGetIsNewUser from 'hooks/useGetIsNewUser';
import useShowPostOrderAdjustments from 'hooks/featureFlags/useShowPostOrderAdjustments';

import Address from './Address';
import Dock from './Dock';
import OrderDetails from './OrderDetails';
import OrderStatus from './OrderStatus';
import SupportBanner from './SupportBanner';

import {
  getTrackingUrl,
  getCanShowMap,
  getCanShowBranchBanner,
  getAdsPageIdentifier,
  getAdsAttributes,
} from './helpers';

import usePolling from './usePolling';

import styles from './styles.module.scss';

function Confirmation() {
  const cookies = new Cookies();

  const location = useLocation();
  const { openModal } = useContext(ModalContext);
  const { user, membership = {} } = useContext(UserContext);

  const canShowPostOrderAdjustments = useShowPostOrderAdjustments();
  const [showPOAToast, setShowPOAToast] = useState(false);

  const order = useSelector(getSelectedOrder);
  const [impactModalOpened, setImpactModalOpened] = useState(false);
  const { status: orderStatus } = order;
  const errors = useSelector(getErrorsForOrder);
  const isOrderFulfilled = getIsOrderFulfilled(order);
  const { orderId: orderIDFromURL } = useParams();
  const isOrderUndefined = orderIDFromURL === 'undefined';
  const isOrderNotFound = errors.length && errors[0].code === NOT_FOUND;
  const dispatch = useDispatch();
  const { customer, fulfill_method: fulfillMethod } = order;

  const { isMarketplacePlatform, platform } = usePlatform();
  const deliveryAddress = get(customer, 'delivery.address');
  const savedRestaurant = useSelector(getRestaurantDetails);
  const restaurant = order.restaurant || savedRestaurant;
  const hqId = restaurant && restaurant.company_id;
  const restaurantId = restaurant && restaurant.id;
  const isValidating = useSelector(getIsOrderValidating);
  const prevIsValidating = usePrevious(isValidating);
  const warnings = useSelector(getWarningsForReorder);
  const prevUser = usePrevious(user);
  const isAuthenticated = !!user && !!user.id && !!restaurant;
  const canShowFullReceipt = isAuthenticated || (!!restaurant && !!order.items);
  const isCancelled = orderStatus === ORDER_STATUSES.canceled;
  const isInProgress = !isOrderFulfilled && !isCancelled;
  const isDelivery = fulfillMethod === FULFILLMENT_METHODS.delivery;
  const isCurbside = fulfillMethod === FULFILLMENT_METHODS.curbside;
  const isDineIn = fulfillMethod === FULFILLMENT_METHODS.dineIn;
  const hasDineInUrl = isDineInUrl();
  const isInSameExperience =
    (isDineIn && hasDineInUrl) || (!isDineIn && !hasDineInUrl);
  const isRestaurantMarketplaceEnabled =
    !isMarketplacePlatform ||
    (isMarketplacePlatform && get(restaurant, 'is_discover_live'));
  const showCurbsideBanner =
    isCurbside &&
    !isCancelled &&
    isWithin24Hours(order.fulfillment_times.updated_at) &&
    restaurant;
  const showTipAdjustment =
    order.adjustments?.can_adjust_tip && canShowPostOrderAdjustments;
  const prevOrder = usePrevious(order);
  const isReorderAllowed =
    // This flag comes from the back end and represents whether the items are available for a reorder
    get(order, 'reorder.can_reorder') &&
    // We don't offer reorder to unauthed users
    user &&
    // We don't offer reorder in the event that the user switched to or from a dine-in order
    isInSameExperience &&
    !isInProgress &&
    // Ensure that the resto is still marketplace enabled for users reordering from `eat.`
    isRestaurantMarketplaceEnabled &&
    // Ensure that the Reorder and Curside banners do not appear at the same time
    !showCurbsideBanner;
  const canShowDock = isReorderAllowed || showTipAdjustment;
  const trackingUrl = getTrackingUrl(order);
  const showCanceledNotice =
    (!isMarketplacePlatform &&
      isCancelled &&
      isSameDay(order.fulfillment_times.updated_at)) ||
    !canShowFullReceipt;
  const userIdMismatch = some(errors, {
    code: USER_ACCOUNT_ID_MISMATCH,
  });
  const isMobile = useIsMobile();
  const showMap = getCanShowMap({
    fulfillMethod,
    isInProgress,
    isMobile,
  });
  const dinerRestaurantSavings = order.diner_restaurant_savings; // before order is accepted

  const isNewUser = useGetIsNewUser(user);

  const { restaurantFetched, restaurantHasNativeApp } =
    useFetchNativeApp(restaurantId);
  const canShowBranchBanner = getCanShowBranchBanner({
    restaurant: savedRestaurant, // need to send saved restaurant specifically because order.restaurant does not have banner data
    restaurantFetched,
    isMarketplacePlatform,
    restaurantHasNativeApp,
  });
  const isMembershipEnabled = useSelector(getRestaurantMembershipEnabled);
  const showMembershipBanner =
    !membership.hasActiveMembership &&
    isMembershipEnabled &&
    canShowFullReceipt &&
    isInProgress &&
    !isMarketplacePlatform;

  const hasObnChannel = getCnChannel();

  const [showAdsScriptOnce, setShowAdsScriptOnce] = useState(true);
  const adsPlatform = hasObnChannel ? 'obn' : platform;
  const canShowPostOrderAds =
    savedRestaurant?.transactional_ads[adsPlatform] &&
    showAdsScriptOnce &&
    cookies.get(CONFIRMATION_ROKT_ADS_COOKIE);

  // Hook to handle reading the Redux store and determining how to handle a reorder attempt
  // This logic should be handle in Sagas and will move with ticket XXXXXX
  useReorder({
    prevIsValidating,
    isValidating,
    warnings,
    errors,
    hqId,
    restaurantId,
  });

  // We poll every minute for order updates
  usePolling();

  // On load, fetch order details from the API to be stored in Redux. The URL is our source of truth here.
  useEffect(() => {
    const queryParams = parse(location.search);
    const guid = queryParams?.guid;

    dispatch({
      type: fetchOrderDetailsXHR.request.TYPE,
      payload: { orderId: orderIDFromURL, guid },
    });
  }, []);

  useEffect(
    () => () => {
      cookies.remove(CONFIRMATION_NEW_USER_COOKIE);
    },
    []
  );

  // START OF ROKT ADS
  const attributes = getAdsAttributes({
    order,
    restaurant: savedRestaurant || order.restaurant,
  });
  const launcher = useRoktLauncher();
  const [selectionPromise, setSelectionPromise] = useState();
  useEffect(() => {
    async function launchAds() {
      if (canShowPostOrderAds) {
        // Return if launcher not initialized or integration attributes not present
        if (!launcher || !attributes.email) {
          return;
        }

        setShowAdsScriptOnce(false);

        cookies.remove(CONFIRMATION_ROKT_ADS_COOKIE);

        const selections = await launcher.selectPlacements({
          identifier: getAdsPageIdentifier({
            isMarketplacePlatform,
            hasObnChannel,
          }),
          attributes,
        });

        setSelectionPromise(selections);
      }
    }

    launchAds();
  }, [launcher, attributes, canShowPostOrderAds]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (selectionPromise) {
        // When the page closes, remove all the Rokt placements
        selectionPromise.close();
      }
    };
  }, [selectionPromise]);
  // END ROKT ADS

  useEffect(() => {
    if (!isAuthenticated) {
      return removeTriggerPostPurchaseModal();
    }

    if (isMarketplacePlatform && order.id && getTriggerPostPurchaseModal())
      openModal(MODAL_TYPE.postPurchaseImpact, {
        modalBackgroundInverse: true,
        noCloseOverlay: true,
        isNewUser,
      });
  }, [order]);

  useEffect(() => {
    if (prevUser !== undefined && prevUser !== user) {
      const queryParams = parse(location.search);
      const guid = queryParams?.guid;

      dispatch({
        type: fetchOrderDetailsXHR.request.TYPE,
        payload: { orderId: orderIDFromURL, guid },
      });
    }
  }, [user]);

  useEffect(() => {
    // Show toast when show tip adjustment changes from true to false
    if (!showTipAdjustment && prevOrder?.adjustments?.can_adjust_tip) {
      setShowPOAToast(true);
    }
  }, [showTipAdjustment]);

  useEffect(() => {
    if (restaurant) {
      const search = window.location?.search;
      // TO DO: check if this is a valid check for OBN https://chownow.atlassian.net/browse/CN-38717
      const isOBN = search && parse(search).cn_channel;
      const shouldUseMpDomain =
        isDirectUrl() && !isOBN && !restaurant.is_direct_live;
      if (shouldUseMpDomain) {
        redirectToMarketplaceMenu({
          restoId: restaurantId,
          orderId: orderIDFromURL,
          shouldReplaceUrl: true,
          source: 'confirmation',
        });
      }
    }
  }, [restaurant]);

  function handleToggleImpactScoreModal() {
    setImpactModalOpened(true);

    openModal(MODAL_TYPE.impactScore, {
      impactModalOpened,
      modalBackgroundInverse: true,
      isNewUser,
    });

    const isNewCustomer = !dinerRestaurantSavings;
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectDinerSeeYourImpact,
      attributes: {
        source: 'order_confirmation',
        order_id: order.id,
        order_score_diner_impact: order.order_restaurant_savings
          ?.toFixed(2)
          .toString(),
        diner_impact_score_lifetime: dinerRestaurantSavings.toString() || '0',
        diner_impact_modal_type: isNewCustomer
          ? 'first_time_diner'
          : 'repeat_diner',
      },
    });
  }

  function handleOnNotification(message) {
    const options = { icon };

    if ('Notification' in window) {
      if (Notification.permission === NOTIFICATION_PERMISSIONS.granted) {
        showNotification(message, options);
      } else if (
        Notification.permission !== NOTIFICATION_PERMISSIONS.denied &&
        Object.prototype.hasOwnProperty.call(Notification, 'requestPermission')
      ) {
        Notification.requestPermission((permission) => {
          if (permission === NOTIFICATION_PERMISSIONS.granted) {
            showNotification(message, options);
          }
        });
      }
    }
  }

  // Ensure the the id pulled from Redux matches that of the order ID in the URL
  // This prevents the app from rendering the wrong order when switching from order history view
  if (userIdMismatch || isOrderUndefined || isOrderNotFound) {
    return <Support isOrderMismatch={!isOrderUndefined} />;
  }

  if (
    orderIDFromURL !== order.id ||
    (canShowFullReceipt && !order.restaurant)
  ) {
    return <LoadingSpinner />;
  }

  // https://chownow.atlassian.net/browse/CN-20679
  // We should pull / augment this once we understand these errors better
  if (order.errors) {
    return <div />;
  }

  return (
    <div>
      {isMobile && canShowBranchBanner && (
        <BranchIOBanner restaurant={restaurant} orderId={order.id} />
      )}
      {
        // TODO fix necessary isRestaurant prop
        // https://chownow.atlassian.net/browse/CN-21142
      }
      <Navbar isRestaurant isSticky={false} doShowBackButton isConfirmation />
      {showCurbsideBanner && (
        <Disclaimer
          disclaimer={
            <span>
              Please call the restaurant upon arrival to receive your order{' '}
              <span className={styles.underline}>
                {formatSimplePhone(restaurant.phone)}
              </span>
            </span>
          }
          handleClick={() => window.open(`tel:${restaurant.phone}`, '_self')}
        />
      )}
      <MapGrid
        isContainer
        className={classNames(styles.mapContainer, {
          [styles.withCurbside]: showCurbsideBanner,
        })}
      >
        {showMap && (
          <MapGrid isMap className={styles.mapWrapper}>
            {canShowFullReceipt && (
              <GoogleMap
                lat={
                  deliveryAddress
                    ? deliveryAddress.latitude
                    : restaurant.address.latitude
                }
                lng={
                  deliveryAddress
                    ? deliveryAddress.longitude
                    : restaurant.address.longitude
                }
                mapHeight={isMobile ? '180px' : '100%'}
              />
            )}
            {!isMobile && showPOAToast && (
              <POAToast closeToast={() => setShowPOAToast(false)} />
            )}
          </MapGrid>
        )}
        <MapGrid className={styles.mapContent}>
          <div className={styles.scrollableContent}>
            <section data-testid="OrderHeader">
              <OrderDetails
                isOrderCancelled={isCancelled}
                order={order}
                restaurant={restaurant}
                handleToggleImpactScoreModal={handleToggleImpactScoreModal}
                isInProgress={isInProgress}
                showMembershipBanner={showMembershipBanner}
              />
            </section>
            <section>
              <OrderStatus
                canShowFullReceipt={canShowFullReceipt}
                isDineIn={isDineIn}
                isInProgress={isInProgress}
                isOrderCancelled={isCancelled}
                order={order}
                restaurant={restaurant}
                trackingUrl={trackingUrl}
                handleOnNotification={handleOnNotification}
              />
            </section>
            {canShowFullReceipt && (
              <>
                {(isAuthenticated || (!isAuthenticated && !isDelivery)) && (
                  <section>
                    <Address
                      order={order}
                      deliveryAddress={deliveryAddress}
                      isOrderFulfilled={isOrderFulfilled}
                      isOrderCancelled={isCancelled}
                      isMarketplacePlatform={isMarketplacePlatform}
                    />
                  </section>
                )}
                <section>
                  <OrderSummary
                    order={order}
                    isAuthenticated={isAuthenticated}
                    isInProgress={isInProgress}
                    isExpandedForced
                    isConfirmation
                    showOrderToggle
                    isReadOnly
                    showLineItems
                  />
                </section>
              </>
            )}
            {showCanceledNotice && <SupportBanner />}
            <section>
              {isReorderAllowed && (
                <TagNotice>
                  Please note the total authorization is subject to change. The
                  final total will appear at checkout.
                </TagNotice>
              )}
            </section>
          </div>

          <div className={styles.reorderWrapper}>
            {isMobile && showPOAToast && (
              <POAToast
                isDockShowing={canShowDock}
                closeToast={() => setShowPOAToast(false)}
              />
            )}
            {canShowDock && (
              <Dock
                order={order}
                restaurantId={restaurant.id}
                isReorderAllowed={isReorderAllowed}
                showTipAdjustment={showTipAdjustment}
                isReceipt={!isInProgress}
              />
            )}
          </div>
        </MapGrid>
      </MapGrid>
    </div>
  );
}

export default Confirmation;
